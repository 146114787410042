import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Logo from "../Logo";
import HeaderNav from "../nav/HeaderNav";

type Props = {
    onLanguageChange: (e: any) => void
}

const Header: React.FC<Props> = ({onLanguageChange}) => {
    return (
        <header>
            <Container>
                <Row>
                    <Col xs={4}>
                        <Logo/>
                    </Col>
                    <Col xs={8}>
                        <HeaderNav onLanguageChange={onLanguageChange}/>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header
