import React, {useState} from 'react'
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {ReactComponent as CreatePost} from './CreatePost.svg'
import {AnimatePresence, motion} from 'framer-motion';
import Hamburger from 'hamburger-react'
import {Link} from "react-router-dom";
import {useLocation} from "react-use";
import Select from "react-select";
import {useIntl} from "react-intl";

type Props = {
    isFooter?: boolean
    onLanguageChange?: (e: any) => void
}

const HeaderNav: React.FC<Props> = ({isFooter = false, onLanguageChange}) => {
    const intl = useIntl();

    const [isOpen, setOpen] = useState(false)

    const scrollToSection = () => {
        setTimeout(() => {
            const productsSection = document.getElementById('products');

            if (productsSection) {
                productsSection.scrollIntoView({behavior: 'smooth'});
            }
        }, 500)
    };

    const location = useLocation();

    const isActive = (path: any) => {
        return location.pathname === path;
    };

    const options = [
        {value: 'en', label: 'English'},
        {value: 'ar', label: 'العربية'}
    ];

    return (
        <>
            {
                isFooter ?
                    <>
                        <Navbar className="lg-nav">
                            <Nav className="">
                                <NavItem>
                                    <Nav.Link as={Link} to="/gallery">{intl.formatMessage({id: "NAV.CG"})}</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link as={Link} to="/"
                                              onClick={scrollToSection}>{intl.formatMessage({id: "NAV.OP"})}</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link as={Link} to="/post" className={'dark'}>
                                        {
                                            !isFooter ? <><CreatePost/>&nbsp;</> : <></>
                                        } <span>{intl.formatMessage({id: "NAV.WMYLC"})}</span>
                                    </Nav.Link>
                                </NavItem>
                            </Nav>
                        </Navbar>
                    </>
                    :
                    <>
                        <Navbar className="lg-nav">
                            <Nav className="">
                                <NavItem>
                                    <Nav.Link as={Link} to="/gallery"
                                              active={isActive("/gallery")}
                                    >{intl.formatMessage({id: "NAV.CG"})}</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link as={Link}
                                              to="/"
                                              onClick={scrollToSection}>{intl.formatMessage({id: "NAV.OP"})}</Nav.Link>
                                </NavItem>
                                <NavItem>
                                    <Nav.Link as={Link} to="/post"
                                              active={isActive("/post")}>
                                        {
                                            !isFooter ? <><CreatePost/>&nbsp;</> : <></>
                                        } <span>{intl.formatMessage({id: "NAV.WMYLC"})}</span>
                                    </Nav.Link>
                                </NavItem>
                                <Select options={options}
                                        value={options.find((option) => (intl.locale === 'ar' ? option.value === 'en' : option.value === 'ar'))}
                                        onChange={onLanguageChange}
                                        className={'lg-select'}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                // borderColor: state.isFocused ? 'grey' : 'red',
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: state.isDisabled ? undefined : state.isSelected ? '#000000' : state.isFocused ? '#FFFFFF' : undefined,
                                                backgroundColor: state.isSelected ? '#FFFFFF' : '#A50034',
                                                ':active': {backgroundColor: '#A50034', color: '#FFFFFF'}
                                            }),
                                        }}
                                />
                            </Nav>
                        </Navbar>

                        <div className={'lg-nav-mobile'}>
                            <Hamburger toggled={isOpen} toggle={setOpen}/>

                            <AnimatePresence>
                                {isOpen && (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                        transition={{duration: 0.2}}
                                        className=""
                                    >
                                        <ul className="nav-links-mobile">
                                            <motion.li
                                                initial={{scale: 0, opacity: 0}}
                                                animate={{scale: 1, opacity: 1}}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: 0.1 + 1 / 10,
                                                }}
                                                key={'lel'}
                                                className=""
                                            >
                                                <Nav.Link as={Link} to="/gallery"
                                                          onClick={() => setOpen((prev) => !prev)}
                                                          active={isActive("/gallery")}>
                                                    <span className="">{intl.formatMessage({id: "NAV.CG"})}</span>
                                                </Nav.Link>
                                            </motion.li>
                                            <motion.li
                                                initial={{scale: 0, opacity: 0}}
                                                animate={{scale: 1, opacity: 1}}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: 0.1 + 2 / 10,
                                                }}
                                                key={'lel'}
                                                className=""
                                            >
                                                <Nav.Link as={Link} to="/" onClick={() => setOpen((prev) => !prev)}>
                                                    <span className="">{intl.formatMessage({id: "NAV.OP"})}</span>
                                                </Nav.Link>
                                            </motion.li>
                                            <motion.li
                                                initial={{scale: 0, opacity: 0}}
                                                animate={{scale: 1, opacity: 1}}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: 0.1 + 3 / 10,
                                                }}
                                                key={'lel'}
                                                className=""
                                            >
                                                <Nav.Link as={Link} to="/post" onClick={() => setOpen((prev) => !prev)}
                                                          active={isActive("/post")}>
                                                    <span className="">{!isFooter ? <>
                                                        <CreatePost/>&nbsp;</> : <></>}<span>{intl.formatMessage({id: "NAV.WMYLC"})}</span></span>
                                                </Nav.Link>
                                            </motion.li>
                                            <motion.li
                                                initial={{scale: 0, opacity: 0}}
                                                animate={{scale: 1, opacity: 1}}
                                                transition={{
                                                    type: "spring",
                                                    stiffness: 260,
                                                    damping: 20,
                                                    delay: 0.1 + 3 / 10,
                                                }}
                                                key={'lel'}
                                                className=""
                                            >
                                                <Select options={options}
                                                        value={options.find((option) => (intl.locale === 'ar' ? option.value === 'en' : option.value === 'ar'))}
                                                        onChange={onLanguageChange}
                                                        className={'lg-select'}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                // borderColor: state.isFocused ? 'grey' : 'red',
                                                            }),
                                                            option: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                color: state.isDisabled ? undefined : state.isSelected ? '#000000' : state.isFocused ? '#FFFFFF' : undefined,
                                                                backgroundColor: state.isSelected ? '#FFFFFF' : '#A50034',
                                                                ':active': {
                                                                    backgroundColor: '#A50034',
                                                                    color: '#FFFFFF'
                                                                }
                                                            }),
                                                        }}
                                                />
                                            </motion.li>
                                        </ul>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </>
            }
        </>
    )
}

export default HeaderNav
