/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, lazy} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import App from "../App";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const HomeComponent = lazy(() => import("../pages/home/Home"));
    const GalleryComponent = lazy(() => import("../pages/gallery/Gallery"));
    const PostComponent = lazy(() => import("../pages/post/Post"));
    const SinglePostComponent = lazy(() => import("../pages/post/SinglePost"));

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    <>
                        {/*<Route path='/*' element={<PrivateRoutes/>}/>*/}
                        <Route index element={<HomeComponent/>}/>
                        <Route path='gallery' element={<GalleryComponent/>}/>
                        <Route path='post' element={<PostComponent/>}/>
                        <Route path='posts/:id' element={<SinglePostComponent/>}/>

                        {/* Page Not Found */}
                        <Route path='*' element={<Navigate to='/error/404'/>}/>
                    </>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
