import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Logo from "../Logo";
import HeaderNav from "../nav/HeaderNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {useIntl} from "react-intl";

const Footer: React.FC = () => {
    const intl = useIntl();

    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 1000); // Update every second to handle year change

        return () => clearInterval(intervalId); // Cleanup function to clear interval
    }, []);

    return (
        <footer>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <Logo/>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <HeaderNav isFooter={true}/>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <div className={'secondary-nav'}>
                            <a href={intl.formatMessage({id: "FOOTER.LSTU_URL"})} target={'_blank'}
                               className={'nav-item'}>{intl.formatMessage({id: "FOOTER.LSTU"})}</a>
                            <a href={intl.formatMessage({id: "FOOTER.PP_URL"})} target={'_blank'}
                               className={'nav-item'}>{intl.formatMessage({id: "FOOTER.PP"})}</a>
                            <a href={intl.formatMessage({id: "FOOTER.CP_URL"})} className={'nav-item'}
                               target={'_blank'}>{intl.formatMessage({id: "FOOTER.CP"})}</a>
                            <span className={'nav-item'}>{intl.formatMessage({id: "FOOTER.COPYRIGHT"})}</span>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <div className={'social-media'}>
                            <a href={'https://www.facebook.com/LGLevant/'} target={'_blank'}
                               className={'icon'}><FontAwesomeIcon icon={faFacebookF}/></a>
                            <a href={'https://twitter.com/lgelevant?lang=en'} target={'_blank'}
                               className={'icon'}><FontAwesomeIcon icon={faXTwitter}/></a>
                            <a href={'https://www.youtube.com/user/LGLevant'} target={'_blank'}
                               className={'icon'}><FontAwesomeIcon icon={faYoutube}/></a>
                            <a href={'https://www.instagram.com/lg_levant/'} target={'_blank'}
                               className={'icon'}><FontAwesomeIcon icon={faInstagram}/></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
