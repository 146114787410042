import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {Outlet} from "react-router-dom";
import React, {Suspense, useState} from "react";
import LoadingIndicator from "./components/LoadingIndicator";
import enMessages from './i18n/messages/en.json'
import arMessages from './i18n/messages/ar.json'
import {IntlProvider} from "react-intl";

const allMessages: Record<string, typeof enMessages> = {
    en: enMessages,
    ar: arMessages
}

function App() {
    const [locale, setLocale] = useState<string>('en');

    // const locale = useLang()
    const messages = allMessages[locale]

    const handleLanguageChange = (e: any) => {
        setLocale(e.value);

        var htmlElement = document.querySelector("html");

        if (htmlElement) {
            htmlElement.lang = 'ar';
        }
    };

    return (
        <div className="App" dir={locale === 'ar' ? 'rtl' : 'ltr'}>
            <Suspense fallback={<LoadingIndicator/>}>
                <IntlProvider locale={locale} messages={messages}>
                    <Header onLanguageChange={handleLanguageChange}/>

                    <Outlet/>

                    <Footer/>
                </IntlProvider>
            </Suspense>
        </div>
    );
}

export default App;
