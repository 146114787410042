import React from 'react'
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

const Logo: React.FC = () => {
    return (
        <div className='logo-container'>
            <Nav.Link as={Link} to="/"> <img loading="lazy" src={'/media/logo/logo.png'} alt={'Logo'}/></Nav.Link>
        </div>
    )
}

export default Logo
